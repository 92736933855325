import styled, { css } from 'styled-components';
import { ModalBodyProps } from './ModalBody';

export const Element = styled.div<ModalBodyProps>`
  ${({ theme, ...props }) => {
    return css`
      background: ${theme.color.white};
      padding: var(--modal-spacing);
    `;
  }};
`;
