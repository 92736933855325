import styled, { css } from 'styled-components';
import { ModalFooterProps } from './ModalFooter';

export const Element = styled.div<ModalFooterProps>`
  ${({ theme, justifyContent = 'flex-end' }) => {
    return css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-shrink: 0;
      justify-content: ${justifyContent};
      gap: var(--modal-gap);
      padding: var(--modal-spacing);
      border-top: ${theme.border.base_neutral_200};
      background: ${theme.color.white};
    `;
  }};
`;
