import React, { MouseEvent, ReactNode } from 'react';
import { Element } from '@/components/modal/ModalHeader/ModalHeader.styles';
import ButtonClose from '@/components/button/ButtonClose/ButtonClose';
import { useModalContext } from '@/components/modal/context/ModalContext';
import { Title } from '@/components/typography';

export interface ModalHeaderProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const ModalHeader = ({ children, ...props }: ModalHeaderProps) => {
  const { id, toggle } = useModalContext();
  return (
    <Element>
      <Title as="h3" id={id} size="base" weight="semi_bold" color="secondary_500" textCase="uppercase">
        {children}
      </Title>
      <ButtonClose onClick={toggle} />
    </Element>
  );
};

export default ModalHeader;
