import { createContext, useContext } from 'react';

interface ModalContextType {
  id: string;
  open: boolean;
  toggle: () => void;
}
const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const useModalContext = () => {
  return useContext(ModalContext);
};

export default ModalContext;
