import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Element, ModalBackdrop, ModalContent } from '@/components/modal/Modal/Modal.styles';
import ModalContext from '@/components/modal/context/ModalContext';

export interface ModalProps {
  /**The id prop represents the aria-labelledby attribute for the modal, and is required for a11y*/
  id: string;
  /**Show or hide the modal.*/
  open: boolean;
  /**Function that toggles the modal.*/
  toggle: () => void;
  /** Whether to position the Modal Content.*/
  position?: 'start' | 'center' | 'end';
  children?: ReactNode;
}

const Modal = ({ id, open, toggle, position = 'center', children, ...props }: ModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const context = useMemo(
    () => ({
      id,
      open,
      toggle,
    }),
    [id, open, toggle]
  );

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key !== 'Escape' || ref.current === null) return;
    toggle();
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (open && ref.current) {
      if (body) {
        body.style.overflow = 'hidden';
      }
      ref.current.style.display = 'flex';
      setTimeout(() => {
        ref.current?.setAttribute('aria-hidden', `${!open}`);
      }, 15);
    } else {
      if (body) {
        body.style.overflow = '';
      }
    }
  }, [open]);

  useEffect(() => {
    document.body.addEventListener('keyup', handleEscape, false);

    // unmount
    return () => {
      document.body.removeEventListener('keyup', handleEscape, false);
    };
  });

  return (
    <ModalContext.Provider value={context}>
      {open &&
        createPortal(
          <Element
            {...props}
            position={position}
            ref={ref}
            aria-hidden={true}
            aria-labelledby={id}
            role="dialog"
            tabIndex={-1}
          >
            <ModalBackdrop onClick={toggle} />
            <ModalContent tabIndex={0}>{children}</ModalContent>
          </Element>,
          document.body
        )}
    </ModalContext.Provider>
  );
};

export default Modal;
