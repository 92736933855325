import styled, { css } from 'styled-components';
import { ModalHeaderProps } from './ModalHeader';

export const Element = styled.div<ModalHeaderProps>`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--modal-spacing);
      background: ${theme.color.white};
      border-bottom: ${theme.border.base_neutral_200};
      > button {
        margin-right: -${theme.spacing.sm};
      }
    `;
  }};
`;
