import React from 'react';

interface LogoIconProps {
  color?: 'default' | 'white';
  width?: number | string | undefined;
  height?: number | string | undefined;
}

const LogoIcon = ({ color = 'default', width = 100, height = 100 }: LogoIconProps) => {
  const bgColor = color === 'default' ? '#b1916a' : '#ffffff';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 800 800"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(50.000000, 57.000000)" fill={bgColor} fillRule="nonzero">
          <g id="Group" transform="translate(0.000000, 0.468085)">
            <g transform="translate(70.153003, 0.000000)" id="Shape">
              <path d="M314.469526,685.007171 C291.957775,685.007171 269.755433,679.068387 250.284735,667.805023 L64.6522958,560.685966 C25.05672,537.841088 0.449824496,495.240031 0.449824496,449.514926 L0.449824496,235.272394 C0.449824496,189.564964 25.0611402,146.968326 64.6522958,124.101355 L250.284735,16.9778792 C289.243812,-5.54884928 339.690821,-5.53117432 378.671997,16.9778792 L564.277916,124.101355 C603.873492,146.941813 628.480388,189.547289 628.480388,235.272394 L628.480388,449.514926 C628.480388,495.24445 603.873492,537.841088 564.277916,560.685966 L378.671997,667.805023 C359.179199,679.068387 336.981277,685.007171 314.469526,685.007171 Z M314.469526,11.9714485 C294.097121,11.9714485 274.016444,17.3402157 256.366842,27.5342452 L70.7609233,134.657721 C34.9268831,155.337416 12.6626595,193.913003 12.6626595,235.276813 L12.6626595,449.519345 C12.6626595,490.905249 34.9268831,529.458742 70.7609233,550.138437 L256.366842,657.257494 C291.657206,677.623459 337.299527,677.623459 372.589891,657.257494 L558.173709,550.138437 C594.02985,529.454323 616.294073,490.922924 616.294073,449.519345 L616.294073,235.272394 C616.294073,193.88649 594.02985,155.332998 558.173709,134.653302 L372.589891,27.5298265 C354.944709,17.335797 334.841932,11.9714485 314.469526,11.9714485 Z"></path>
              <path d="M411.447304,627.112861 L217.465228,627.112861 C175.880591,627.112861 137.124841,604.736369 116.310422,568.714814 L19.3326443,400.789498 C-1.48177481,364.772361 -1.48177481,320.032634 19.3326443,284.019916 L116.310422,116.090182 C137.124841,80.0774637 175.880591,57.6965536 217.465228,57.6965536 L411.447304,57.6965536 C453.031941,57.6965536 491.809791,80.0774637 512.62421,116.090182 L609.597568,284.019916 C630.411987,320.032634 630.411987,364.772361 609.597568,400.789498 L512.62421,568.714814 C491.809791,604.736369 453.054041,627.112861 411.447304,627.112861 Z M217.465228,69.9011091 C180.225584,69.9011091 145.518679,89.9312504 126.887807,122.174785 L29.8879284,290.122194 C11.2570563,322.36131 11.2570563,362.443686 29.8879284,394.682801 L126.887807,562.63463 C145.496578,594.873745 180.225584,614.92598 217.465228,614.92598 L411.447304,614.92598 C448.709048,614.92598 483.415954,594.873745 502.046826,562.63463 L599.046704,394.682801 C617.677576,362.443686 617.677576,322.36131 599.046704,290.122194 L502.046826,122.174785 C483.415954,89.9356691 448.691367,69.9011091 411.447304,69.9011091 L217.465228,69.9011091 Z"></path>
            </g>
            <path
              d="M0.667440493,139.226696 C0.667440493,139.226696 42.0885323,276.278287 217.413215,310.342341 C382.394781,342.391451 399.867578,472.019561 415.90383,489.601721 C419.970355,494.024878 460.485319,534.491685 532.414187,545.542949 C532.414187,545.542949 426.66244,510.431654 433.81422,438.525524 C425.99058,408.59299 446.177339,335.723575 343.992642,307.408299 C423.38712,322.697134 434.12805,378.99628 434.12805,378.99628 C434.12805,378.99628 441.62018,356.553507 457.881859,351.330559 C426.096663,378.262769 432.5147,445.339218 484.570638,455.117887 C433.47829,424.535798 472.211939,392.6546 472.211939,392.6546 C472.211939,392.6546 503.117528,395.252818 532.409767,381.263093 C554.545807,370.861382 586.441506,387.449326 586.441506,387.449326 C586.441506,387.449326 592.607595,389.066585 598.800206,384.510865 C604.988396,379.959565 600.435656,375.094534 597.827776,373.459601 C595.206635,371.842342 582.512006,359.469875 581.212486,350.03145 C574.538081,334.742615 560.879861,333.293268 560.879861,333.293268 C560.879861,333.293268 559.262092,330.840869 562.347346,328.719874 C565.432601,326.59888 574.874011,315.883439 571.138996,304.178201 C560.230101,306.758744 551.292587,322.869465 551.292587,322.869465 C551.292587,322.869465 547.217222,299.604807 532.069417,295.057925 C526.866917,310.832821 538.089642,328.074738 538.907367,328.724293 C538.907367,328.724293 531.101407,345.65248 509.955478,337.681076 C488.805128,329.709672 464.401559,247.887892 393.462801,232.908369 C337.800032,219.590292 264.593744,270.012516 264.593744,270.012516 C264.593744,270.012516 121.089618,324.981622 0.667440493,139.226696 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
