import React, { ReactNode } from 'react';
import { Element } from '@/components/modal/ModalBody/ModalBody.styles';

export interface ModalBodyProps {
  children?: ReactNode;
}

const ModalBody = ({ children, ...props }: ModalBodyProps) => {
  return <Element>{children}</Element>;
};

export default ModalBody;
