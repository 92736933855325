import React, { ReactNode } from 'react';
import { Element } from '@/components/modal/ModalFooter/ModalFooter.styles';

export interface ModalFooterProps {
  children?: ReactNode;
  justifyContent?: 'space-between' | 'flex-end';
}

const ModalFooter = ({ children, ...props }: ModalFooterProps) => {
  return <Element {...props}>{children}</Element>;
};

export default ModalFooter;
