import styled, { css } from 'styled-components';

export const Element = styled.div<{ position?: 'start' | 'center' | 'end' }>`
  ${({ theme, position, ...props }) => {
    return css`
      --modal-gap: ${theme.spacing.sm}; // .5rem
      --modal-spacing: calc(${theme.spacing.base} - ${theme.spacing.sm} * 0.5); // 12px
      display: none;
      opacity: 0;
      align-items: ${position};
      position: fixed;
      z-index: 2300;
      inset: 0;

      transition: all 0.15s ease;
      &[aria-hidden='false'] {
        opacity: 1;
      }

      margin: ${theme.spacing.md} ${theme.spacing.base};
    `;
  }};
`;

export const ModalBackdrop = styled.div`
  ${({ theme, ...props }) => {
    return css`
      width: 100%;
      height: 100%;
      height: 100vh;
      background: ${theme.color.dark};
      opacity: 0.7;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      display: flex;
    `;
  }};
`;

export const ModalContent = styled.div`
  ${({ theme, ...props }) => {
    return css`
      margin: 0 auto;
      border-radius: ${theme.radius.sm};
      background: ${theme.color.white};
      box-shadow: ${theme.box_shadow.sm};
      overflow: hidden;

      min-width: calc(100vw - ${theme.spacing.md});
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        min-width: 30rem;
      }
    `;
  }};
`;
